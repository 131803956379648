import {
  L_PHONE_MASK_INPUT, L_ACTIVE_CHECK_LF_BOX, L_DATE_BIRTH_MASK_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'
import { can } from '@core/libs/acl/utils'

export const CONTACT_TYPE_BILLING = 0
export const CONTACT_TYPE_SHIPPING = 1
export const CONTACT_TYPE_ACCOUNT_HOLDER = 2
export const CONTACT_TYPE_CAN_PLACE_ORDERS = 3

export default function config() {
  const ACCESS_ABILITY_FOR_CONTACTS = { action: 'change', subject: 'Contacts' }
  // Table Handlers
  const tableColumns = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: can(ACCESS_ABILITY_FOR_CONTACTS.action, ACCESS_ABILITY_FOR_CONTACTS.subject) ? '10%' : '0' },
    },
    {
      key: 'first_name',
      label: 'Name',
    },
    {
      key: 'cell_no',
      label: 'Phone',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'default',
      label: 'Default',
    },
  ]

  const trashTableColumns = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '50px' },
    },
    {
      key: 'first_name',
      label: 'Name',
    },
    {
      key: 'cell_no',
      label: 'Phone',
    },
    {
      key: 'email',
      label: 'Email',
    },
  ]

  const createFields = {
    first_name: {
      type: TEXT_INPUT,
      label: 'First Name(s)',
      rules: 'required',
      placeholder: 'Type...',
    },
    last_name: {
      type: TEXT_INPUT,
      label: 'Last Name(s)',
      rules: 'required',
      placeholder: 'Type...',
    },
    middle_name: {
      type: TEXT_INPUT,
      label: 'Middle Name',
      placeholder: 'Type...',
    },
    pseudonym: {
      type: TEXT_INPUT,
      label: 'Pseudonym',
      placeholder: 'Type...',
    },
    email: {
      type: TEXT_INPUT,
      label: 'Email Address',
      rules: 'required|email',
      placeholder: 'Type...',
    },
    phone_one: {
      type: L_PHONE_MASK_INPUT,
      label: 'Primary Phone Number',
      rules: 'required',
    },
    phone_two: {
      type: L_PHONE_MASK_INPUT,
      label: '',
    },
    phone_three: {
      type: L_PHONE_MASK_INPUT,
      label: '',
    },
    phone_four: {
      type: L_PHONE_MASK_INPUT,
      label: '',
    },
    // cell_no: {
    //   type: L_PHONE_MASK_INPUT,
    //   label: 'Primary Phone Number',
    //   // rules: 'required',
    //   JSONName: 'cell_no_object',
    //   // options: {
    //   //   options: {
    //   //     blocks: [0, 3, 3, 4, 6],
    //   //     delimiter: '(',
    //   //     delimiters: ['(', ')', '-', ' ext '],
    //   //     numericOnly: true,
    //   //   },
    //   // },
    //   // rules: 'required|numeric',
    //   placeholder: 'Type...',
    // },
    // phone_no: {
    //   type: L_PHONE_MASK_INPUT,
    //   label: 'Additional Phone Number',
    //   JSONName: 'phone_no_object',
    //   // options: {
    //   //   options: {
    //   //     blocks: [0, 3, 3, 4, 6],
    //   //     delimiter: '(',
    //   //     delimiters: ['(', ')', '-', ' ext '],
    //   //     numericOnly: true,
    //   //   },
    //   // },
    //   // rules: 'numeric',
    //   placeholder: 'Type...',
    // },
    is_active: {
      label: 'Active',
      type: L_ACTIVE_CHECK_LF_BOX,
      rules: 'required',
    },
    job_title: {
      type: TEXT_INPUT,
      label: 'Title',
      placeholder: 'Type...',
    },
    birthday: {
      type: L_DATE_BIRTH_MASK_INPUT,
      label: 'Date of Birth',
    },
  }

  const phoneNumberFields = ['cell_no', 'phone_no']
  const phoneNumberExtraFields = [
    {
      field: 'cell_no_object',
      extraField: 'cell_no_ext_val',
    },
    {
      field: 'phone_no_object',
      extraField: 'phone_no_ext_val',
    },
  ]
  const phoneNumberFieldsJSONName = [
    {
      name: 'cell_no',
      JSONName: 'cell_no_object',
    },
    {
      name: 'phone_no',
      JSONName: 'phone_no_object',
    }]
  const contactTypesCustomer = [
    {
      id: CONTACT_TYPE_ACCOUNT_HOLDER,
      name: 'Account Holder',
      value: 'is_account_holder',
    },
    {
      id: CONTACT_TYPE_BILLING,
      name: 'Billing',
      value: 'is_billing',
    },
    {
      id: CONTACT_TYPE_CAN_PLACE_ORDERS,
      name: 'Can Place Orders',
      value: 'can_place_orders',
    },
    {
      id: CONTACT_TYPE_SHIPPING,
      name: 'Shipping',
      value: 'is_shipping',
    },
  ]
  const phoneOptions = [
    {
      key: 'mobile',
      label: 'Cell',
      indexName: 'phone_one',
    },
    {
      key: 'work',
      label: 'Work',
      indexName: 'phone_two',
    },
    {
      key: 'home',
      label: 'Home',
      indexName: 'phone_three',
    },
    {
      key: 'fax',
      label: 'Fax',
      indexName: 'phone_four',
    },
  ]

  return {
    tableColumns,
    createFields,
    phoneNumberFields,
    trashTableColumns,
    contactTypesCustomer,
    phoneNumberExtraFields,
    phoneNumberFieldsJSONName,
    ACCESS_ABILITY_FOR_CONTACTS,
    phoneOptions,
  }
}

const contactTypesAsNeeded = [
  { title: 'Billing', key: 'is_billing' },
  { title: 'Shipping', key: 'is_shipping' },
  { title: 'Account Holder', key: 'is_account_holder' },
  { title: 'Can place orders', key: 'can_place_orders' },
]

export const convertTypesToTitleByTypeValue = data => {
  const dataObjectKeys = Object.keys(data)

  return contactTypesAsNeeded.map(type => {
    if (dataObjectKeys.includes(type.key) && data[type.key]) {
      return type.title
    }
    return ''
  }).filter(title => title !== '').join(', ')
}
