var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('portal',{attrs:{"to":"body-top"}},[_c('customer-name',{attrs:{"title":" "}})],1),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.trashTableColumns,"create-page-url":{ name: 'home-customers-contacts-create' },"table-config-options":_vm.tableConfigOptions(),"back-to-list-path":"home-customers-contacts-list"},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted defaultIconColor",staticStyle:{"padding":"2px 6px"},attrs:{"icon":"LTrashIcon","size":"30"},on:{"click":function($event){return _vm.remove(data.item)}}}),_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted defaultIconColor",staticStyle:{"padding":"4px 6px"},attrs:{"icon":"LLoadIcon","size":"28"},on:{"click":function($event){return _vm.restoreContact(data.item)}}})],1)]}},{key:"cell(type)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getTypeText(data.item))+" ")]}},{key:"cell(first_name)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.first_name + ' ' + data.item.last_name)+" ")]}},{key:"cell(cell_no)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.cell_no_object ? _vm.getPhoneNumber(data.item.cell_no_object) : '—')+" ")]}},{key:"filter",fn:function(){return [_vm._v(" "+_vm._s('')+" ")]},proxy:true},{key:"pagination",fn:function(){return [_vm._v(" "+_vm._s('')+" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }