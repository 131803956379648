<template>
  <div class="pb-2">
    <portal to="body-top">
      <customer-name title=" " />
    </portal>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="trashTableColumns"
      :create-page-url="{ name: 'home-customers-contacts-create' }"
      :table-config-options="tableConfigOptions()"
      back-to-list-path="home-customers-contacts-list"
    >
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            icon="LTrashIcon"
            size="30"
            style="padding: 2px 6px"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="remove(data.item)"
          />
          <feather-icon
            icon="LLoadIcon"
            size="28"
            style="padding: 4px 6px"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="restoreContact(data.item)"
          />
        </div>
      </template>
      <template #cell(type)="{ data }">
        {{ getTypeText(data.item) }}
      </template>
      <template #cell(first_name)="{ data }">
        {{ data.item.first_name + ' ' + data.item.last_name }}
      </template>
      <template #cell(cell_no)="{ data }">
        {{ data.item.cell_no_object ? getPhoneNumber(data.item.cell_no_object) : '—' }}
      </template>
      <template #filter>
        {{ '' }}
      </template>
      <template #pagination>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>

<script>

// components
import LTable from '@/views/components/LTable/LTable.vue'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import config, { convertTypesToTitleByTypeValue } from '../contactsConfig'
import CustomerName from '../../../components/CustomerName.vue'

export default {
  name: 'ContactsList',
  components: {
    LTable,
    CustomerName,
    // eslint-disable-next-line vue/no-unused-components
    ButtonDashed,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  methods: {
    tableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getInActiveContactsList`,
      }
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`)
        .then(
          () => {
            this.refetchData()
          },
        )
    },
    restoreContact(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/setActive`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    setDefault(data) {
      const {
        item: { id },
      } = data
      const customerId = this.$route.params.id
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        {
          customerId,
          id,
        },
        `${this.MODULE_NAME}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },
    update(data, e) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name: 'home-customers-contacts-update',
          params: { contact_id: data.id },
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name: 'home-customers-contacts-update',
          params: { contact_id: data.id },
        })
      }
    },

    getTypeText(data) {
      const { contact_types } = data

      // Making new array for Contact types to get their Title, by type value
      const convertedTypesToTitleByTypeValue = convertTypesToTitleByTypeValue(contact_types)

      return convertedTypesToTitleByTypeValue
    },
    getPhoneNumber(obj) {
      const data = JSON.parse(obj)

      return `${data.formatNational} ${data.cell_no_ext_val ? `Ext.${data.cell_no_ext_val}` : ''}`
    },
  },
  setup() {
    const MODULE_NAME = 'contacts'
    const { trashTableColumns } = config()
    return {
      trashTableColumns,
      MODULE_NAME,
    }
  },
}
</script>
