<template>
  <div class="portal-body-top mt-0">
    <h4
      class="text-center font-weight-bolder d-flex align-items-center justify-content-center"
      style="gap: 12px"
    >
      <span
        v-if="customerStatus !== null"
      >
        <feather-icon
          :icon="checkVerificationStatus()"
          size="16"
          :class="`${customerStatus === 2 ? 'customer-status-icon':''}`"
        />
      </span>
      <b-spinner
        v-else
        small
        type="grow"
      />
      {{ getCustomerName(customer) ? getCustomerName(customer) : '—' + ' ' + $t(title) }}
    </h4>
  </div>
</template>

<script>
import config from '@/views/main/customers/customConfig'
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'CustomerName',
  components: {
    BSpinner,
  },
  props: {
    title: {
      type: String,
      require: true,
      default: () => ' ',
    },
  },
  beforeRouteLeave() {
    this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_STATUS`, null)
  },
  data() {
    return {
      verificationIconName: 'LWarningIcon',
      defaults: null,
      customerVerificationData: null,
    }
  },
  computed: {
    customer() {
      return this.$store.state[this.MODULE_NAME].customer
    },
    customerStatus() {
      return this.$store.state[this.MODULE_NAME].customerStatus
    },
    verificationDefaultStatusList() {
      return this.$store.state.listModule.verificationDefaultStatus
    },
  },
  async created() {
    await this.$store.dispatch(`${this.MODULE_NAME}/getVerification`)
      .then(res => {
        const { data } = res.data
        // this.customerVerificationData = data.customer.verification
        this.customerVerificationData = data.customer.customer_detail?.status
      }).catch(err => {
        this.errorNotification(this, err)
      })

    this.$store.dispatch(`${this.MODULE_NAME}/getDefaults`)
      .then(res => {
        const { data } = res.data

        this.defaults = data

        // This is to get the Default info to discount on the page General info update
        this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_DEFAULT_DATA`, this.defaults)
        // const getCustomerNotVerifiedStatus = this.verificationDefaultStatusList.find(status => status.text === this.defaults.verification_default_status)

        // this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_STATUS`, this.customerVerificationData ? this.customer.customer_detail.status : getCustomerNotVerifiedStatus.id)
      })
  },
  methods: {
    checkVerificationStatus() {
      const status = +this.customerStatus

      // eslint-disable-next-line no-nested-ternary
      return status === 0 ? 'LWarningIcon' : status === 1 ? 'LTickIcon' : status === 2 ? 'LNoIcon' : status === 3 ? 'LClockIcon' : 'LWarningIcon'
    },
  },
  setup() {
    const MODULE_NAME = 'customers'
    const { getCustomerName } = config()
    return {
      MODULE_NAME,
      getCustomerName,
    }
  },

}
</script>
<style lang="scss">
.customer-status-icon {
  fill: red !important;
}
</style>
